import React from 'react'
import logo from '../asset/logo_placeholder.svg'
const Footer = () => {
  return (
    <> 
      <footer className='bg-gray-50 text-white p-4'>
        <div className='flex justify-between'>
          <div>
            <img className='w-16 ' src={logo} alt="logo" />
            <h1 className='text-gray-400'>Application Name</h1>
          </div>
          <div className='grid place-items-center'>
            <p className='text-gray-400'>© 2021 Application Name. All rights reserved.</p>
          </div>
          <div className="subtitle">
            <p className='text-gray-400'>Privacy Policy</p>
            <p className='text-gray-400'>Terms of Service</p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer