import './App.css';

import Header from "./components/Header";
import Footer from "./components/Footer";
function App() {
  
  return (
    <>
      <Header/>
      <div className="main [&>*:nth-child(even)]:px-4 xl:[&>*:nth-child(even)]:px-28 sm:[&>*nth-child(even)]:px-14">
        <div className="banner">
          <img src="https://storage.googleapis.com/playsee-web/about/images/about_landing_pic.jpg" alt="placeholder" />
        </div>
        <section>
          <h1 className='text-4xl font-bold p-4'>像朋友一樣</h1>
          <p className='text-xl'>這個AI助理不僅是個功能強大的工具，更像是個朋友，能夠了解你的需求和習慣。無論是需要提醒你的事情，還是只是想聊天，它都能陪著你，隨時提供幫助和支持。
          </p>
          <br />
          <p>
          Playsee的使命是透過科技的力量重新喚起這些情感。我們的目標是促進在地人與人之間的聯繫和合作，並為當地商家提供邁向成功所需的一切。我們的願景是將數位世界和本地世界結合起來，利用科技增強本地事物的本質。
          </p>
        </section>
        <br />
        <section className='w-full'>
          <h1 className='text-4xl font-bold p-4'>隨時隨地的幫手</h1>
          <p className='text-xl'>這個助理不僅存在於網頁上，還可以在你的手機、平板和其他智能裝置上找到它。無論你身在何處，只要有設備在手，它就能立刻為你效勞，解答問題，提供建議，總之，是你生活中不可或缺的好幫手。</p>
        </section>
        <br />
        <section className='w-full'>
          <h1 className='text-4xl font-bold p-4'>讓生活更輕鬆</h1>
          <p className='text-xl'>這個智能助理不僅僅是個工具，更像是個能提升生活品質的小夥伴。它會根據你的喜好和行為習慣，提供個性化的建議，幫你更有效地管理時間，提高效率，甚至改善生活方式。讓你輕鬆享受更智慧、更愉悅的生活。</p>
        </section>
      </div>
      <Footer/>
    </>
  );
}

export default App;
