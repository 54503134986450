import React from 'react'
import logo from '../asset/logo_placeholder.svg'
const Header = () => {
  return (
    <div className='w-full flex items-center'>
      <img className='w-16' src={logo} alt="logo" />
      <h1 >Application Name</h1>
    </div>
  )
}

export default Header